<template>
  <b-card class="p-100">
    <b-card-header>
      <slot name="shortcut" />
      <b-row class="w-100">
        <b-col>
          <h2 class="pb-50">
            <slot name="header">
              검색 필터
            </slot>
          </h2>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <slot name="body" />
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="카테고리별 검색"
          >
            <b-row>
              <b-col
                cols="12"
                :xl="lw"
              >
                <VSelect
                  v-model="categorySelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryOptions"
                  placeholder="검색 항목 선택"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </VSelect>
              </b-col>
              <b-col
                v-if="categorySelected.format === 'date'"
                cols="12"
                :xl="12 - lw"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="inputValue"
                    class="form-control"
                    :config="{ mode: 'range'}"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="addTag"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                v-else-if="categorySelected.format === 'checkbox'"
                cols="12"
                :xl="12 - lw"
                class="d-flex align-items-center"
              >
                <b-form-checkbox-group
                  v-model="selectedValue"
                  :options="categorySelected.options"
                  name="buttons-1"
                />
              </b-col>
              <b-col
                v-else-if="categorySelected.format === 'range'"
                cols="12"
                :xl="12 - lw"
                class="d-flex align-items-center"
              >
                <b-input-group>
                  <tagby-range-input
                    v-model="inputValue"
                    @keyup.enter="addTag"
                    @keydown.enter="keydownEnter"
                  />
                  <b-input-group-append>
                    <b-button
                      class="add-button"
                      variant="outline-primary"
                      @click="addTag"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                v-else
                cols="12"
                :xl="rw"
              >
                <b-input-group>
                  <b-form-input
                    v-model="inputValue"
                    class="d-inline-block"
                    placeholder="검색 텍스트 입력"
                    @keyup.enter="addTag"
                    @keydown.enter="keydownEnter"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="addTag"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-tags
            v-model="tagParams"
            input-id="tags-basic"
            class="mb-2"
            :tag-validator="() => false"
            invalid-tag-text="이곳에 직접 입력할 수 없습니다"
            placeholder="위에서 입력한 값을 보여줍니다"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex">
          <b-button
            variant="gradient-primary"
            class="mr-1"
            @click="search"
          >
            검색
          </b-button>
          <slot name="buttons" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import {
  ref, watch, onMounted, computed,
} from '@vue/composition-api'
import moment from 'moment'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import TagbyRangeInput from '@/components/TagbyRangeInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    VSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    TagbyRangeInput,
  },
  props: {
    categoryOptions: {
      type: Array,
      required: true,
    },
    initialSearchParams: {
      type: Object,
      default: () => ({}),
    },
    lw: {
      type: Number,
      default: 2,
    },
    rw: {
      type: Number,
      default: 4,
    },
  },
  emits: ['search'],
  watch: {
    initialSearchParams() {
      this.setTagParams()
    },
  },
  setup(props, context) {
    const categorySelected = ref(props.categoryOptions[0])
    const inputValue = ref(null)
    const tagParams = ref([])
    const selectedValue = ref([])

    const addTag = () => {
      if (inputValue.value) {
        tagParams.value.push(`${categorySelected.value.label}:${inputValue.value}`)
        inputValue.value = null
      }
    }
    const syncronizeTagParamsWithSelectedValue = () => {
      if (categorySelected.value.format === 'checkbox') {
        const valueBytext = categorySelected
          .value
          .options
          .reduce((previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.text]: currentValue.value,
          }), {})

        const splitedTags = tagParams.value.map(tag => tag.split(':'))

        selectedValue.value = splitedTags
          .filter(([key]) => key !== categorySelected.value.label)
          .concat(splitedTags
            .filter(([key]) => key === categorySelected.value.label)
            .map(([, text]) => valueBytext[text]))
      }
    }
    watch([tagParams, categorySelected], () => {
      syncronizeTagParamsWithSelectedValue()
    })

    const setTagParams = () => {
      const optionsByparamName = props
        .categoryOptions
        .reduce((previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.paramName]: currentValue,
        }), {})

      tagParams.value = Object
        .entries(props.initialSearchParams)
        .map(([key, value]) => value.map(v => {
          const option = optionsByparamName[key]
          if (option.format === 'checkbox') {
            const textsByValue = option
              .options
              .reduce((previousValue, currentValue) => ({
                ...previousValue,
                [currentValue.value]: currentValue.text,
              }), {})
            return `${option.label}:${textsByValue[v]}`
          } if (option.format === 'date') {
            const splitedMoment = v.split(' to ').map(d => moment(`${d}z`))
            const diffTime = splitedMoment[1].diff(splitedMoment[0])
            const diffDays = moment.duration(diffTime).asDays()
            if (diffDays < 1) {
              return `${option.label}:${splitedMoment[0].format('YYYY-MM-DD')}`
            }
            return `${option.label}:${splitedMoment.map(d => d.format('YYYY-MM-DD')).join(' to ')}`
          }
          return `${option.label}:${v}`
        }))
        .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], [])

      syncronizeTagParamsWithSelectedValue()
    }

    onMounted(() => {
      setTagParams()
    })

    watch(selectedValue, (newValue, oldValue) => {
      const textsByValue = categorySelected
        .value
        .options
        .reduce((previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.value]: currentValue.text,
        }), {})

      if (newValue.length > oldValue.length) {
        newValue.forEach(value => {
          const text = textsByValue[value]
          if (!oldValue.includes(value)) {
            tagParams.value.push(`${categorySelected.value.label}:${text}`)
          }
        })
      } else {
        oldValue.forEach(value => {
          if (!newValue.includes(value)) {
            const text = textsByValue[value]
            const i = tagParams.value.findIndex(tag => tag === `${categorySelected.value.label}:${text}`)
            if (i !== -1) {
              tagParams.value.splice(i, 1)
            }
          }
        })
      }
    })

    const datetimeSplit = datetime => {
      const splitedDatetime = datetime.split(' to ')
      if (splitedDatetime.length === 1) {
        splitedDatetime.push(splitedDatetime[0])
      }
      return splitedDatetime
    }

    const datetimePreprocessing = datetime => datetimeSplit(datetime)
      .map((date, i) => (i === 0 ? moment(date).startOf('date') : moment(date).endOf('date'))
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'))
      .join(' to ')

    const search = () => {
      const searchParams = props
        .categoryOptions
        .map(element => element.paramName)
        .reduce((previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: [],
        }), {})

      const categoryOptionsByLabel = props
        .categoryOptions
        .reduce((previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.label]: currentValue,
        }), {})

      tagParams.value.forEach(tag => {
        const [label, value] = tag.split(':')
        const option = categoryOptionsByLabel[label]
        if (option.format === 'date') {
          searchParams[option.paramName].push(datetimePreprocessing(value))
        } else if (option.format === 'checkbox') {
          const valueBytext = option
            .options
            .reduce((previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.text]: currentValue.value,
            }), {})
          searchParams[option.paramName].push(valueBytext[value])
        } else {
          searchParams[option.paramName].push(value)
        }
      })

      context.emit('search', searchParams)
    }

    const keydownEnter = e => {
      if (e.metaKey || e.ctrlKey) {
        addTag()
        search()
      }
    }
    const test = e => console.log(e)

    return {
      test,
      categorySelected,
      inputValue,
      tagParams,
      addTag,
      selectedValue,
      search,
      keydownEnter,
      setTagParams,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
