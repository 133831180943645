<template>
  <BRow class="w-100 mb-1">
    <BCol class="d-flex">
      <BBadge
        pill
        variant="primary"
        class="mr-1"
        href="#"
        @click="$emit('reload', {state: ['ACTIVE'], date_type: ['EXPOSING', 'WILL_SHOW']})"
      >
        유효한 배너목록
      </BBadge>
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  emits: [
    'reload',
  ],
}
</script>
