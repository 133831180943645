<template>
  <div class="tagby-range">
    <b-form-input
      :value="prependValue"
      class="prepend"
      placeholder="최솟값"
      type="number"
      :disabled="disabled"
      @input="$emit('input', inputPrependValue($event))"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <div class="middle">
      <span>
        ~
      </span>
    </div>
    <b-form-input
      :value="appendValue"
      class="append"
      placeholder="최댓값"
      type="number"
      :disabled="disabled"
      @input="$emit('input', inputAppendValue($event))"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
    />
  </div>
</template>
<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BFormInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '~',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const prependValue = computed(() => {
      const splitedValue = props.value?.split('~') ?? []
      return splitedValue?.[0]
    })
    const appendValue = computed(() => {
      const splitedValue = props.value?.split('~') ?? []
      return splitedValue?.[1]
    })
    const inputPrependValue = value => `${value ?? ''}~${appendValue.value ?? ''}`
    const inputAppendValue = value => `${prependValue.value ?? ''}~${value ?? ''}`
    return {
      prependValue,
      appendValue,
      inputPrependValue,
      inputAppendValue,
    }
  },
}
</script>

<style scoped>
.tagby-range {
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.357rem;
}
.tagby-range > * {
  text-align: center;
}
.input-group .tagby-range {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-color: #d8d6de;
}
.dark-layout .tagby-range {
  border-color: var(--dark);
}
.tagby-range:focus-within {
  border-color: var(--primary)!important;
}
.tagby-range > * {
  border-radius: 0!important;
  border-color: transparent!important;
}
.tagby-range .middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.5rem;
}
.tagby-range .add-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
